import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


function Home(props) {
  
  const location = useLocation();
  useEffect(() => {
    console.log(location.hash);
   if(location.hash !== ""){
    const locationWithoutHash = location.hash.replace(/#/g, ""); 
    const section = document.getElementById(locationWithoutHash)
    props.scroll(section.offsetTop);
   }
   else{
    props.scroll(0);
   }
  }, [location.key]);
  

  return (
    <>
       <section id="about-us" className="about">
            <div className="container">
              <div className="about-content">
                <h2 className="section-heading">About Us</h2>
                <h5 className="section-subheading">
                  We are a leading construction company specializing in
                  high-quality construction services.
                </h5>
                <p>
                Welcome to Borsa Hungary Kft, your premier construction company in Budapest.
        We specialize in delivering high-quality construction services with a
        commitment to excellence.
                </p>
                <p>
                At Borsa Hungary Kft, we take pride in our work. With a team of dedicated
        professionals and a wealth of experience, we are here to turn your
        construction dreams into reality. We offer a wide range of services
        tailored to your needs, including residential construction, renovation,
        and more.
                </p>
              </div>
             <div><img
                src="/images/About_Us_Logo.png"    /*{constructionImageUrls[0]}*/
                alt="Construction Image"
                className="about-image"
              /></div>
            </div>
          </section>

       <section id="services" className="services">
            <div className="container">
              <h2 className="section-heading">Our Services</h2>
              <div className="services-list">
                <div className="service">
                  <img
                    src="/images/logos/Icon_S3.png"
                    alt="Service 1 Icon"
                    className="service-icon"
                  />
                  <h3 className="service-heading">Residential Construction</h3>
                  <p className="service-description">
                  Crafting beautiful homes with precision and care. Your dream home is our
    passion.
                  </p>
                </div>
                <div className="service">
                  <img
                    src="/images/logos/Icon_S2.png"
                    alt="Service 2 Icon"
                    className="service-icon"
                  />
                  <h3 className="service-heading">Commercial Construction</h3>
                  <p className="service-description">
                  Elevating commercial spaces with expertise. Your business, our commitment.
                  </p>
                </div>
                <div className="service">
                  <img
                    src="/images/logos/Icon_S.png"
                    alt="Service 3 Icon"
                    className="service-icon"
                  />
                  <h3 className="service-heading">Renovation</h3>
                  <p className="service-description">
                  Transforming spaces, creating style. Breathe new life into your property.
                  </p>
                </div>
              </div>
            </div>
          </section>

    </>
  );
}

export default Home;
